import type { Filter } from './analytics'
import type { UserRole } from './permissions'
import type { NormalizedFieldEntry } from './sourceValue'

export interface BdeseBaseSection {
  content: string
  title: string
  description: string
}

export type BdeseSection = BdeseBaseSection & {
  id: string
  order: number
  isPublished: boolean
}

export interface BdeseParams {
  year: number
  bdeseFilters: Filter[]
  realizedYearsNumber: number
  trendYearsNumber: number
  isFiscal: boolean
}

export type BdeseAttachment = {
  id: string
  attachmentId: string
  filename: string
  uploadedAt: Date
  size: number
  isPublished: boolean
}

export type Bdese = BdeseParams & {
  id: string
  createdAt: Date
  publishedAt: Date
  bdeseSections: BdeseSection[]
  bdeseAttachments: BdeseAttachment[]
}

export enum SectionTitleLevel {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4'
}

// TODO: Add ids to section titles
export interface SectionTitle {
  title: string
  level: SectionTitleLevel
}

interface Section {
  id: string
  description: string
  titles: SectionTitle[]
}

export type TableOfContent = Section[]

export type BdeseQuery = {
  measure: string
  rowDimensions: string[]
  columnDimension?: string
  filters?: Filter[]
  dateRange?: [string, string]
}

export enum ExplorerChartContentType {
  BAR_CHART = 'BAR_CHART',
  DOUGHNUT_CHART = 'DOUGHNUT_CHART'
}

export enum ExplorerTableContentType {
  YEAR_TABLE = 'YEAR_TABLE',
  STANDARD_TABLE = 'STANDARD_TABLE'
}

export type ExplorerContentType =
  | ExplorerChartContentType
  | ExplorerTableContentType

export type ExplorerNewContentContext = {
  isNewContent: true
  type: ExplorerContentType
  query?: undefined
}

export type ExplorerExistingContentContext = {
  isNewContent?: false
  query: BdeseQuery
  associatedNormalizedFields: NormalizedFieldEntry[]
  type: ExplorerContentType
  withTotal?: boolean
}

export enum DynamicContentType {
  EXPLORER = 'EXPLORER',
  ATTACHMENT_ZONE = 'ATTACHMENT_ZONE'
}

type BaseDynamicContentMeta = {
  yPosition: number
  height: number
  nodePos: number
  type: DynamicContentType
}

export type ExplorerContentMeta = BaseDynamicContentMeta & {
  explorerContext: ExplorerExistingContentContext
  importedAt: string
  type: DynamicContentType.EXPLORER
}

export type AttachmentZoneContentMeta = BaseDynamicContentMeta & {
  attachmentIds: string[]
  updatedAt: string | null
  type: DynamicContentType.ATTACHMENT_ZONE
}

export type DynamicContentMeta = ExplorerContentMeta | AttachmentZoneContentMeta

export type ExplorerContext =
  | ExplorerNewContentContext
  | ExplorerExistingContentContext

export enum BdeseTemplate {
  BASIC_SMALL_COMPANY = 'BASIC_SMALL_COMPANY',
  BASIC_LARGE_COMPANY = 'BASIC_LARGE_COMPANY'
}

export enum BdeseAccessType {
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER'
}

export type BdesePermission = {
  email: string
  isInvite: boolean
  role: UserRole
  accessType: BdeseAccessType
}

export type BdesePermissionUpdated = Omit<BdesePermission, 'accessType'> & {
  isNewInvite: boolean
  accessType: BdesePermission['accessType'] | null
}

export type BdeseSectionConfig = {
  templateSectionId: string
  title: string
  description: string
  template: BdeseTemplate
}

export type BdeseTemplateSectionsConfig = {
  [key in BdeseTemplate]: Omit<BdeseSectionConfig, 'template'>[]
}
